import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const ErrorMessageWrapper = ({
    errorMessages = [], children, hideMessages = false, hideBorder
}) => {
    const [isInView, setIsInView] = useState(false);
    const errorInputRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const [entry] = entries;

                setIsInView(entry.isIntersecting);
            },
            { threshold: 1 }
        );

        if (errorInputRef.current) {
            observer.observe(errorInputRef.current);
        }

        return () => {
            if (errorInputRef.current) {
                observer.unobserve(errorInputRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!isInView && errorMessages.length) {
            errorInputRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [errorMessages.length]);

    return (
        <div
            className={`errorMessageWrapper${!hideBorder && errorMessages.length ? ' error_border' : ''}`}
            ref={errorInputRef}
        >
            {children}
            {
                !hideMessages && errorMessages.length ? (
                    <div className='errorMessageWrapper_errorMessages'>
                        {errorMessages.map((message) => (
                            <p key={message} className='errorMessage'>
                                {message}
                            </p>
                        ))}
                    </div>
                ) : null
            }
            {/* <style>{`
                .errorMessageWrapper.error_border .${dynamicErrorClass} {
                    border-color: var(--additional_error) !important;
                }
            `}</style> */}
        </div>
    );
};

ErrorMessageWrapper.propTypes = {
   children: PropTypes.node,
   errorMessages: PropTypes.array,
   hideMessages: PropTypes.bool,
   hideBorder: PropTypes.bool,
};

export default ErrorMessageWrapper;
