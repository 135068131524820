const initialState = {
   dataIsFetching: true,
   innerActionInProgress: true,
   transactionsInProgress: true,
   notesInProgress: true,
   noteActionProgress: false,
   tagsActionProgress: false,
   errors: {},
   isFilterAction: false,
   members: {},
   currentMember: {},
   currentMemberInitialData: {},
   memberTransactions: {},
   memberNotes: [],
   tags: [],
   sortedClassVersion: 0,
   courses: [],
   lastLogin: '',
   pauseCourseInProgress: true,
   addCourseInProgress: false,
   popupChangesProgress: false,
   chooseCourses: [],
   selectedFilters: [],
   defaultMemberId: 0,
   isUnsubscribeEmail: false,
   isFetchingPagination: false,
   addMemberInProgress: true,
   selectedCommunity: null,
   isLoadingCommunity: false,
   initialLength: 0,
   isEmptyByFilter: false,
   showFullScreenLoader: false,
};

export default initialState;
